<template>

  <div class="card-deck mb-3 text-left">
    <div class="card box-shadow">
      <div class="card-header" v-if="title">
        <h4 class="my-0 font-weight-normal"><span style="color: orange;">&#8226; </span> {{title}}</h4>
      </div>
      <div class="card-body">
        <template v-if="!encrypted">
        <p class="text-right">
          <button class="btn badge badge-pill badge-secondary" @click="copyToClipboard"><b-icon icon="clipboard"></b-icon></button>
          <button class="btn badge badge-pill badge-secondary" @click="modal('edit')"><b-icon icon="pencil-square"></b-icon></button>
        </p>

        <show-more-text :text="body" :lines="4"/>
        
        </template>
         <template v-else>
           <b-button block variant="outline-primary" @click="modal('unlock')">Unlock <b-icon icon="lock"></b-icon></b-button>
         </template>

      </div>

      <div class="card-footer bg-transparent blockquote-footer text-right">
        {{updated_time}}

        <button class="btn badge badge-pill badge-danger" @click="removeNote(note.id)"><b-icon icon="trash"></b-icon></button>
      </div>
    </div>  
  </div>

</template>

<script>
import ShowMoreText from '../../helper/ShowMoreText'
export default {
  name: 'note-item',
  components:{
    ShowMoreText,
  },
  props: {
    note: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      'id': this.note.id,
      'title': this.note.title,
      'body': this.note.body,
      'editing': this.note.editing,
      'encrypted': this.note.encrypted,
      'updated_time': this.note.updated_time,
    }
  },
  watch: {
    note(){
      this.id = this.note.id
      this.title = this.note.title
      this.body = this.note.body
      this.editing = this.note.editing
      this.encrypted = this.note.encrypted
      this.updated_time = this.note.updated_time
    }
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      }
    }
  },
  methods: {
    setIsLoading(isLoading){
      this.$store.dispatch('isLoading', isLoading);
    },
    makeToast(message,variant, append = false) {
      this.toastCount++
      this.$bvToast.toast(`${message}`, {
        title: 'Info',
        autoHideDelay: 5000,
        appendToast: append,
        variant: `${variant}`
      })
    },
    copyToClipboard(){
      navigator.clipboard.writeText(this.body);
      this.makeToast("Copied Note!", "warning");
    },
    modal(modalType){
      this.$emit('modalOpen', this.note, modalType);
    },
    removeNote(id) {
      this.setIsLoading(true);
      this.$store.dispatch('deleteNote', id)
      .catch(error => {
        this.makeToast(error.response.data.message, "danger");
      })
      .finally(()=>{
        this.setIsLoading(false);
      })
    },
  }
}
</script>
