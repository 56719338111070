<template>
  
  <div>
    <div class="card-deck mb-3 text-left">
      <div class="card box-shadow">
        <div class="card-body">
          <form action="#" @submit.prevent="addNote" autocomplete="off">

            <div class="form-group">
              <b-form-input
                  size="sm"
                  v-model="newNote"
                  maxlength=60
                  placeholder="Title"
                  autocomplete="off"
              ></b-form-input>

            </div>
            
            <div class="form-group">
              <b-form-textarea
                size="sm"
                v-model="newBody"
                placeholder="Body"
                rows="3"
                max-rows="3"
                maxlength=5000
                ref="notePanel"
              >
              </b-form-textarea>
              <b-form-text>{{charCounter}}</b-form-text>
            </div>

            <div class="form-group">
              <b-row>
                <b-col class="pb-2 text-left">
                  <b-form-checkbox switch class="mr-n2" v-model="encryptNote">Lock</b-form-checkbox>
                </b-col>

                <b-col class="pb-2 text-right">
                  <button class="btn btn-primary" type="submit"><b-icon icon="check"></b-icon> Save</button>
                </b-col>
              </b-row>
            </div>

            <div class="form-group" v-show="encryptNote">
              <input type="password" v-model="notePassword" class="form-control form-control-sm" placeholder="Note Password" autocomplete="off">
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>

  <!--end new-->
</template> 

<script>
export default {
  name: 'note-item-insert',
  data() {
    return {
      newNote: '',
      newBody: '',
      encryptNote: false,
      notePassword: '',
    }
  },
  watch: {
  },
  computed: {
    charCounter(){
      return this.newBody.length + " of 5000 chars";
    }
  },
  mounted(){
    if (this.$route.params.publicNoteTitle && this.$route.params.publicNoteBody){
      this.newNote = this.$route.params.publicNoteTitle;
      this.newBody = this.$route.params.publicNoteBody;
    }
  },
  directives: {
  },
  methods: {
    makeToast(message,variant,append = false) {
      this.toastCount++
      this.$bvToast.toast(`${message}`, {
        title: 'Info',
        autoHideDelay: 5000,
        appendToast: append,
        variant:`${variant}`
      })
    },
    setIsLoading(isLoading){
      this.$store.dispatch('isLoading', isLoading);
    },
    addNote() {
      if (this.newBody.trim().length == 0) {
        return;
      }

      if (this.encryptNote === true){
        if (this.notePassword.trim().length == 0) {
          return;
        }
      }

      this.setIsLoading(true);
      this.$store.dispatch('addNote', {
        id: this.idForNote,
        title: this.newNote,
        body: this.newBody,
        encryptNote: this.encryptNote,
        notePassword: this.notePassword,
      })
      .catch(error => {
        this.makeToast(error.response.data.message, "danger");
        const errors = error.response.data.errors;
        for (const key in errors) {
          if (errors.hasOwnProperty(key)) {
            this.makeToast(errors[key], "danger");
          }
        }
      })
      .finally(()=> {
        this.resetFields();
        this.setIsLoading(false);
      })

      this.idForNote++
      
    },
    resetFields()
    {

      this.newNote = '';
      this.newBody = '';
      this.encryptNote = false;
      this.notePassword = '';
    
    }
  }
}
</script>
