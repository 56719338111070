<template>
  <div></div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch('clearNotes')
    this.$store.dispatch('destroyToken')
      .finally(() => {
        this.$router.push({ name: 'home' })
      })
  }
}
</script>
