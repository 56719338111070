<template>
  
  <div>
    <b-modal id="modal-unlock" title="Unlock Note">
      <form action="#" @submit.prevent="decrypt">

        <div class="form-group">
          <input type="password" v-model="password" class="form-control" placeholder="password">
        </div>
      </form>
      <template #modal-footer>
        <b-button @click="cancel()">
          Cancel
        </b-button>
        <b-button variant="primary" @click="decrypt">
          Unlock
        </b-button>
      </template>
    </b-modal>
  </div>

</template>

<script>
export default {
  name: 'note-item-modal-unlock',
  props: {
      note: {
        type: Object
      },
  },
  data() {
    return {
      password:'',
    }
  },
  watch: {
    'note':function(){
    }
  },
  computed:{
  },
  directives: {
  },
  methods: {
    makeToast(message,variant,append = false) {
      this.toastCount++
      this.$bvToast.toast(`${message}`, {
        title: 'Info',
        autoHideDelay: 5000,
        appendToast: append,
        variant:`${variant}`
      })
    },
    decrypt(){

        if (this.password != null) { 
          
          this.$store.dispatch('decryptNote', {
            
            'id': this.note.id,
            'encrypted': this.note.wasEncrypted,
            'password': this.password

          }).then(response => {
            this.makeToast("Updated Successfully!", "success");
          }).catch(error => {
            this.makeToast(error.response.data.message, "danger");
          })

        }

        this.$bvModal.hide("modal-unlock")
        this.reset()

    },
    cancel(){
        this.$bvModal.hide("modal-unlock")
        this.reset()
    },
    reset(){
        this.password = '';
    }
  }
}
</script>
