<template>
  
  <div>
      <b-modal id="modal-edit" 
               size="xl" 
               title="Edit Note"
               @show="initModal">
        <form action="#" @submit.prevent="addNote" autocomplete="off">

          <div class="form-group">
              <b-form-input
                size="sm"
                v-model="title"
                maxlength=60
                placeholder="Title"
                autocomplete="off"
            ></b-form-input>
          </div>
          
          <div class="form-group">
            
            <b-form-textarea
              v-model="body"
              placeholder="Body"
              rows="9"
              max-rows="9"
              maxlength=5000
              ref="notePanel"
            >
            </b-form-textarea>
            <b-form-text v-if="body">{{body.length}} of 5000 chars</b-form-text>
            
          </div>

          <div class="form-group">
            <b-form-checkbox switch class="mr-n2" v-model="wasEncrypted">
              Lock
            </b-form-checkbox>
          </div>

          <div class="form-group" v-show="wasEncrypted">
            <input type="password" v-model="notePassword" class="form-control form-control-sm" placeholder="Note Password">
          </div>

        </form>
        <template #modal-footer="{cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button @click="cancel()">
            Cancel
          </b-button>
          <!-- Button with custom close trigger value -->
          <b-button variant="primary" @click="update">
            Update
          </b-button>
        </template>
      </b-modal>
    </div>

  <!--end new-->
</template>

<script>
export default {
  name: 'note-item-modal-edit',
  props: {
    note: {
      type: Object
    },
  },
  data() {
    return {
      notePassword: '',
      title: '',
      body: '',
      wasEncrypted: false,
      notePassword:'',
    }
  },
  computed: {
  },
  watch: {
    'note':function() {
        this.initModal();
    },
  },
  created(){
  },
  mounted(){
  },
  directives: {
  },
  methods: {
    initModal(){
      this.title = this.note.title
      this.body = this.note.body
      this.wasEncrypted = this.note.wasEncrypted
    },
    makeToast(message,variant,append = false) {
      this.toastCount++
      this.$bvToast.toast(`${message}`, {
        title: 'Info',
        autoHideDelay: 5000,
        appendToast: append,
        variant:`${variant}`
      })
    },
    update(){
      // Check for note password
      if (this.wasEncrypted === true){
        if (this.notePassword.trim().length == 0) {
          return
        }
      }

      this.$store.dispatch('updateNote', {
        'id': this.note.id,
        'title': this.title,
        'body': this.body,
        'editing': false,
        'encryptNote': this.wasEncrypted,
        'notePassword': this.notePassword,
      }).then(response => {
         this.makeToast("Updated Successfully!", "success");
       }).catch(error => {
        this.makeToast(error.response.data.message, "danger");
      });

      this.$bvModal.hide("modal-edit")
      this.reset()
      
    },
    cancel(){
      this.$bvModal.hide("modal-edit")
      this.reset()
    },
    reset(){
    }
  }
}
</script>
