<template>

    <b-container> 

    <div class="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
      <h1 class="display-4">Sign in</h1>
    </div>

    <div class="card-deck mb-3 text-center">
        <div class="card box-shadow">
          <div class="card-body">
            <form action="#" @submit.prevent="login">

              <div class="form-group text-left">
                <label for="exampleInputEmail1">Email address</label>
                <input type="email" class="form-control" v-model="email" placeholder="Email" required>
              </div>
              
              <div class="form-group text-left">
                <label for="exampleInputEmail1">Password</label>
                <input type="password" class="form-control" placeholder="Password" v-model="password" required>
              </div>

              <b-container fluid>
                 <b-row>
                    <b-col>
                      <b-button pill block variant="primary" size="md" type="submit">Sign in</b-button>
                    </b-col>
                    <b-col align-self="center">
                      <b-link class="text-right" to="reset">Forgot password?</b-link>
                    </b-col>
                  </b-row>
              </b-container>

            </form>
          </div>
        </div>
      </div>
    </b-container>
</template>

<script>
export default {
  name: 'login',
  title: 'EINMB | Login',
  props: {
    dataSuccessMessage: {
      type: String,
    }
  },
  data() {
    return {
      email: '',
      password: '',
    }
  },
  watch:{
    dataSuccessMessage(){}
  },
  mounted(){
    if(this.dataSuccessMessage)
      this.makeToast(this.dataSuccessMessage,"success")
  },
  methods: {
    setIsLoading(isLoading){
      this.$store.dispatch('isLoading', isLoading);
    },
    makeToast(message,variant,append = false) {
      this.toastCount++
      this.$bvToast.toast(`${message}`, {
        title: 'Info',
        autoHideDelay: 5000,
        appendToast: append,
        variant:`${variant}`
      })
    },
    login() {

      this.setIsLoading(true);
      this.$store.dispatch('retrieveToken', {
        email: this.email,
        password: this.password,
      }).then(response => {
          this.$router.push(this.$route.query.redirect || { name: 'notes' })
        })
        .catch(error => {
          this.password = '';
          this.makeToast(error.response.data.message, "danger");

        }).finally(()=>{
          this.setIsLoading(false);
        });
    }
  }
}
</script>
