<template>
  <div class="card-deck mb-3 text-center">
    <div class="card box-shadow">
      <div class="card-body">
        No notes found!
      </div>
    </div>  
  </div>

</template>

<script>
export default {
  name: 'note-no-notes',
}
</script>

