<template>
  <div>
    <div id="spinner-back" v-bind:class="{show: spinnerIsActive}"></div>
    <div id="spinner-front" v-bind:class="{show: spinnerIsActive}">
        <div class="loadingio-spinner">
            <div class="noteSpinner">
                <div></div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name:'spinner',
    props: {
        spinnerIsActive: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
        }
    },
    watch: {
        spinnerIsActive(){
        }
    },
}
</script>
<style>
/* [FULL SCREEN SPINNER] */
#spinner-back, #spinner-front {
    position: fixed;
    width: 100vw;
    transition: all 1s;
    visibility: hidden;
    opacity: 0;
}
#spinner-back {
    z-index: 998;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
}
#spinner-front {
    z-index: 999;
    color: #fff;
    text-align: center;
    margin-top: 50vh;
    transform: translateY(-50%);
}
#spinner-back.show, #spinner-front.show {
    visibility: visible;
    opacity: 1;
}

/* [DOES NOT QUITE MATTER] */
html, body {
    margin: 0;
    padding: 0;
}

/*Spiner Starts here*/
@keyframes noteSpinner {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.noteSpinner div {
  position: absolute;
  width: 71.5px;
  height: 71.5px;
  border: 5.5px solid #ffffff;
  border-top-color: transparent;
  border-radius: 50%;
}
.noteSpinner div {
  animation: noteSpinner 1s linear infinite;
  top: 55.00000000000001px;
  left: 55.00000000000001px
}
.loadingio-spinner {
  width: 110px;
  height: 110px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.noteSpinner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.noteSpinner div { box-sizing: content-box; }

</style>