import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
axios.defaults.baseURL = process.env.VUE_APP_URL

export const store = new Vuex.Store({
  state: {
    token: localStorage.getItem('access_token') || null,
    filter: 'all',
    notes: [],
    isLoading: false,
    nextPageUrl: null,
  },
  getters: {
    nextPageUrl(state){
        return state.nextPageUrl;
    },  
    isLoading(state){
        return state.isLoading;
    },
    loggedIn(state) {
        return state.token !== null
    },
    notes(state) {
        return state.notes
    }
  },
  mutations: {
    isLoading(state, isLoading){

        state.isLoading = isLoading;

    },
    unlockNoteFromModal(state, note) {

        const index = state.notes.findIndex(item => item.id == note.id)
        let mynote =  state.notes[index];
    
        state.notes.splice(index, 1, {
            id: note.id,
            title: mynote.title,
            body: note.body,
            editing: mynote.editing,
            encrypted:false,
            updated_time: mynote.updated_time,
            wasEncrypted: true,//static for frontend usage
        })

    },
    addNote(state, note) {
    
        state.notes.unshift({
            id: note.id,
            title: note.title,
            body: note.body,
            encrypted: note.encrypted,
            editing: false,
            updated_time: note.updated_time,
            wasEncrypted: note.encrypted //static for frontend usage
        })
    
    },
    updateNote(state, note) {
    
        const index = state.notes.findIndex(item => item.id == note.id)
        state.notes.splice(index, 1, {
            'id': note.id,
            'title': note.title,
            'body': note.body,
            'editing': note.editing,
            'encrypted': note.encrypted,
            'updated_time': note.updated_time
        })
    
    },
    deleteNote(state, id) {
    
        const index = state.notes.findIndex(item => item.id == id)
        state.notes.splice(index, 1)
    
    },
    updateFilter(state, filter) {
    
        state.filter = filter
    
    },
    retrieveNotes(state, notes) {

        state.notes.push.apply(state.notes,notes)
    
    },
    retrieveToken(state, token) {
    
        state.token = token
    
    },
    destroyToken(state) {
    
        state.token = null
    
    },
    clearNotes(state) {
        
        state.notes = []

    },
  },
  actions: {
    resetConfirm(context, data){

        return new Promise((resolve, reject) => {
            axios.post('/reset/confirm', {
                token: data.token,
                email: data.email,
                password: data.password,
                password_confirmation: data.password_confirmation
            })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })

    },
    resetVerify(context, data){

        return new Promise((resolve, reject) => {
            axios.get('/reset/verify?'+
                'email=' + data.email +
                '&token=' + data.token)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })

    },
    resetPassword(context, data){

        return new Promise((resolve, reject) => {
            axios.post('/reset', {
                email: data.email,
                h_captcha_response: data.hcaptcharesponse
            })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })

    },
    isLoading(context, spinnerIsActive){
        
        context.commit('isLoading', spinnerIsActive);

    }, 
    // Public Notes Start
    savePublicNote(context, data){

        var note_token = localStorage.getItem(data.name + "_public_token");
        if (note_token !== null)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + note_token;

        return new Promise((resolve, reject) => {
            axios.post('/save', {
                name: data.name,
                body: data.body ?? "",
            })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })

    },
    retrievePublicNote(context, data){

        var name = "";
        if(data.name != "")
            name = "/" + data.name;

        // Clear the Authorization header
        delete axios.defaults.headers.common['Authorization'];
        
        // Get Subscription for notification if it exists
        var subscription = localStorage.getItem(data.name);
        var note_token = localStorage.getItem(data.name + "_public_token");

        if (note_token !== null)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + note_token;

        return new Promise((resolve, reject) => {
            axios.post('/get' + name, {
                    subscription: subscription
                })
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })

    },
    lockPublicNote(context, data){
        
        var public_note_token = data.note_name + "_public_token";

        return new Promise((resolve, reject) => {
            axios.post('/public/lock', {
                    note_name: data.note_name,
                    password: data.password
                })
                .then(response => {
                    localStorage.setItem(public_note_token, response.data.token);
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })

    },
    unlockPublicNote(context, data){
        
        var public_note_token = data.name + "_public_token";
        var subscription = localStorage.getItem(data.name);

        return new Promise((resolve, reject) => {
            axios.post('/public/unlock', {
                    note_name: data.name,
                    password: data.password,
                    subscription: subscription,
                })
                .then(response => {
                    localStorage.setItem(public_note_token, response.data.token);
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })

    },
    removeLock(context, data){
        
        var note_name_token = data.name + "_public_token";
        var note_token = localStorage.getItem(note_name_token);

        if (note_token !== null)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + note_token;

        return new Promise((resolve, reject) => {
            axios.get('/public/removelock')
                .then(response => {
                    localStorage.removeItem(note_name_token);
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })

    },
    publicLogout(context, data){

        var note_name_token = data.name + "_public_token";
        var note_token = localStorage.getItem(note_name_token);

        if (note_token !== null)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + note_token;

        return new Promise((resolve, reject) => {
            axios.get('/public/logout')
                .then(response => {
                    localStorage.removeItem(note_name_token);
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })

    },
    // Notifications
    enableNotifications(context, data){

        return new Promise((resolve, reject) => {
            axios.post('/subscribe', {
                note_name: data.name,
                subscription: data.subscription,
            })
            .then(response => {
                // Save subscription to LocalStorage
                localStorage.setItem(response.data.notification.note_name, response.data.notification.subscription);
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })

    },
    disableNotifications(context, data){

        return new Promise((resolve, reject) => {
            axios.post('/unsubscribe', {
                note_name: data.name,
                subscription: localStorage.getItem(data.name),
            })
            .then(response => {
                    
                // Check if subscription exists in localStorage
                if (localStorage.getItem(data.name)) {
                    
                    // subscription exists, so remove it
                    localStorage.removeItem(data.name);
                
                }

                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })

    },
    unlockNoteFromModal(context, note) {

        context.commit('unlockNoteFromModal', note);

    },
    decryptNote(context, note) {

        return new Promise((resolve, reject) => {
            
            axios.patch('/notes/unlock/' + note.id, {
                encrypted: note.encryptNote,
                password: note.password
            })
            .then(response => {
                context.commit('updateNote', response.data)
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
        });

    },
    retrieveName(context) {

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token

        return new Promise((resolve, reject) => {
            axios.get('/user')
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })

    },
    clearNotes(context) {

        context.commit('clearNotes')

    },
    register(context, data) {

        return new Promise((resolve, reject) => {
            axios.post('/register', {
                    username: data.username,
                    email: data.email,
                    password: data.password,
                    password_confirmation: data.password_confirmation,
                    h_captcha_response: data.hcaptcharesponse
                })
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })

    },
    destroyToken(context) {

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token

        if (context.getters.loggedIn) {
            return new Promise((resolve, reject) => {
                axios.post('/logout')
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        localStorage.removeItem('access_token')
                        context.commit('destroyToken')
                    });
            })
        }

    },
    retrieveToken(context, credentials) {

        return new Promise((resolve, reject) => {
            axios.post('/login', {
                    email: credentials.email,
                    password: credentials.password,
                })
                .then(response => {
                    const token = response.data.access_token

                    localStorage.setItem('access_token', token)
                    context.commit('retrieveToken', token)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })

    },
    retrieveNotes(context, data) {

        var search = "";
        if (data.search)
            search = "?q="+ data.search;
        return new Promise((resolve, reject) =>{
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
        
            axios.get('/notes'+search)
            .then(response => {
                context.state.nextPageUrl = response.data.next_page_url
                context.commit('retrieveNotes', response.data.data)
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
        })

    },
    retrieveNewNotes(context) {

        return new Promise((resolve, reject) =>{
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
            
            axios.get(context.state.nextPageUrl)
            .then(response => {
                context.state.nextPageUrl = response.data.next_page_url
                context.commit('retrieveNotes', response.data.data)
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
        })

    },
    addNote(context, note) {

        return new Promise((resolve, reject) => {

            axios.post('/notes', {
                title: note.title,
                body: note.body,
                encrypted: note.encryptNote,
                password: note.notePassword,
            })
            .then(response => {
                context.commit('addNote', response.data)
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
        
        })

    },
    updateNote(context, note) {

        return new Promise((resolve, reject) => {
            
            axios.patch('/notes/' + note.id, {
                title: note.title,
                body: note.body,
                encrypted: note.encryptNote,
                password: note.notePassword
            })
            .then(response => {
                context.commit('updateNote', response.data)
                resolve(response);
            })
            .catch(error => {
                reject(error);
            })
        });

    },
    deleteNote(context, id) {

        return new Promise((resolve, reject) => {
            axios.delete('/notes/' + id)
                .then(response => {
                    context.commit('deleteNote', id)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                })
        });

    },
    updateFilter(context, filter) {

        context.commit('updateFilter', filter)

    }
  }
})
