import NoteList from '../components/notes/NoteList'
import Home from '../components/main/Home'
import Login from '../components/auth/Login'
import Logout from '../components/auth/Logout'
import Register from '../components/auth/Register'
import NotFoundComponent from '../components/notfound/NotFoundComponent'
import Reset from '../components/auth/Reset'
import ResetConfirm from '../components/auth/ResetConfirm'

const routes = [
    {
        path: '/notes',
        name: 'notes',
        component: NoteList,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        props: true,
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout
    },
    {
        path: '/reset',
        name: 'reset-password',
        component: Reset,
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/reset/confirm',
        name: 'reset-confirm',
        component: ResetConfirm,
        props: route => ({ token: route.query.token, email:  route.query.email}),
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/:name',
        name: 'home_name',
        component: Home
    },
    { 
        path: '/:catchAll(.*)', 
        name: 'NotFound',
        component: NotFoundComponent
    }
]

export default routes