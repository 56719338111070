<template>
    <div>
      <!--<div class="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <h1 class="display-4">Welcome {{ name }}</h1>
        <p class="lead">Quickly build an effective pricing table for your potential customers with this Bootstrap example.
          It's built with default Bootstrap components and utilities with little customization.</p>
      </div>-->
    </div>
</template>

<script>
export default {
  name: 'note-message',
  props: {
  },
  data() {
    return {
      name: '',
    }
  },
  created(){
    this.$store.dispatch('retrieveName')
      .then(response=>{
        this.name = response.data.name
    })
  },
}
</script>
