<template>
  <div>
    <b-row>
    
      <b-col md="2">
        <b-input-group>
          <b-form-input type="text" v-model="search" debounce="500" placeholder="Search notes" @keyup.enter="initSearch" @keyup.esc="cancelEdit"></b-form-input>
        </b-input-group>
      </b-col>

      <b-col cols="12" md="8">
        
        <note-message></note-message>
      
        <note-item-insert></note-item-insert>
        
        <transition-group name="fade" enter-active-class="animated fadeInUp" leave-active-class="animated fadeOutDown">
          <note-item v-for="note in notes" 
                    :key="note.id" 
                    :note="note" 
                    v-on:modalOpen="modalOpen">
          </note-item>
        </transition-group>

        <NoNotes v-if="!notes.length"/>

        <note-item-modal-edit :note="noteEdit"></note-item-modal-edit>

        <note-item-modal-unlock :note="noteUnlock"></note-item-modal-unlock>
          
        
      </b-col>
      <b-col md="2">
      </b-col>
    </b-row>
  </div>
</template>

<script>
import NoteItem from './NoteItem'
import NoteItemModalEdit from './NoteItemModalEdit'
import NoteItemInsert from './NoteItemInsert'
import NoteMessage from './NoteMessage'
import NoteItemModalUnlock from './NoteItemModalUnlock'
import NoNotes from './NoNotes'

export default {
  name: 'note-list',
  title: 'EINMB | My Notes',
  components: {
    NoteItem,
    NoteItemModalEdit,
    NoteItemInsert,
    NoteMessage,
    NoteItemModalUnlock,
    NoNotes
  },
  data() {
    return {
      noteUnlock: {},
      noteEdit: {},
      modalType: '',
      search: '',
    }
  },
  mounted(){
    this.scroll();
  },
  created() {
    window.addEventListener('scroll', this.scroll);
    
    if (this.notes.length == 0){
      this.retrieveNotes();
    }
  },
  destroyed(){
    window.removeEventListener('scroll', this.scroll);
  },
  computed: {
    notes() {

      return this.$store.getters.notes
    
    }
  },
  watch:{
    search(){

      this.$store.dispatch('clearNotes')
      this.retrieveNotes();
    
    }
  },
  methods: {
    scroll(){

      let bottomOfWindow = false
      let res = Math.abs(Math.floor(Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)) + window.innerHeight-document.documentElement.offsetHeight)
      if (res <= 1)
        bottomOfWindow = true
      
      if (bottomOfWindow && (this.$store.getters.nextPageUrl != null)) {
        this.retrieveNewNotes();
      }

    },
    setIsLoading(isLoading){

      this.$store.dispatch('isLoading', isLoading);
    
    },
    modalOpen(note, modalType){

      if (modalType === 'unlock'){
        this.noteUnlock = note;
        this.$bvModal.show("modal-unlock");
      }
      else if (modalType === 'edit'){
        this.noteEdit = note;
        this.$bvModal.show("modal-edit")
      }

    },
    retrieveNotes(){

      this.setIsLoading(true);
      this.$store.dispatch('retrieveNotes',{
        search: this.search
      })
      .catch(error => {
        this.makeToast(error.response.data.message, "danger");
      })
      .finally(()=>{
        this.setIsLoading(false);
      })

    },
    retrieveNewNotes(){

      this.setIsLoading(true);
      this.$store.dispatch('retrieveNewNotes')
      .catch(error => {
        this.makeToast(error.response.data.message, "danger");
      })
      .finally(()=>{
        this.setIsLoading(false);
      })

    },
    makeToast(message,variant, append = false) {

      this.toastCount++
      this.$bvToast.toast(`${message}`, {
        title: 'Info',
        autoHideDelay: 5000,
        appendToast: append,
        variant: `${variant}`
      })

    },
  }
}
</script>