<template>
  <div class="page-wrapper flex-center">
     
     <b-form>
        <!--Start unlocked Note-->
        <div v-if="!showLoginForm">
          <b-form-group>
            <b-form-textarea
              id="textarea"
              v-model="note"
              placeholder="Enter something..."
              rows="16"
              max-rows="16"
              debounce="500"
              maxlength=5000
              ref="notePanel"
            >
            </b-form-textarea>
            
            <b-form-text >{{charCounter}}</b-form-text>

          </b-form-group>
          <b-form-group>
            <div>
              <b-input-group>
                <b-form-input
                id="input-live"
                v-model="furl"
                aria-describedby="input-live-help input-live-feedback"
                trim
                disabled
              ></b-form-input>

                <b-input-group-append>
                  <b-button variant="outline-secondary" @click="saveToMyNotes">Save <b-icon icon="cloud-arrow-down"></b-icon></b-button>
                  <b-button variant="outline-secondary" @click="copyToClipboard">Copy <b-icon icon="clipboard"></b-icon></b-button>
                  <b-button variant="outline-secondary" v-b-tooltip.hover title="Views">{{views}} <b-icon icon="eye"></b-icon></b-button>
                  <b-button variant="outline-secondary" v-b-tooltip.hover title="Subscriptions">{{subscriptions}} <b-icon icon="bookmark-heart"></b-icon></b-button>
                  <b-button v-if="locked" variant="outline-secondary" @click="submitRemoveLock" v-b-tooltip.hover title="Remove Lock">Remove Lock <b-icon icon="unlock"></b-icon></b-button>
                  <b-button v-if="locked" variant="outline-secondary" @click="submitLogOut" v-b-tooltip.hover title="Logout">Logout <b-icon icon="box-arrow-right"></b-icon></b-button>
                  <b-button v-if="!locked" variant="outline-secondary" @click="showLoginToLockNote" v-b-tooltip.hover title="Lock note">Lock <b-icon icon="lock"></b-icon></b-button>
                  <b-button v-if="!notifyMe" variant="primary" @click="subscribeForNotifications" v-b-tooltip.hover title="Enable notifications"><b-icon icon="bell"></b-icon></b-button>
                  <b-button v-if="notifyMe" variant="primary" @click="unsubscribeNotifications" v-b-tooltip.hover title="Disable notifications"><b-icon icon="bell-slash-fill"></b-icon></b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-form-group>
      </div>
      <!--End unlocked Note-->
      <!--Start locked Note-->
      <div v-if="showLoginForm">
        <b-container class="w-25">
          <b-row align-h="center" class="mb-3">
            <label>{{loginTitleText}}</label>
            <b-form-input 
              type="password" 
              v-model="notePassword" 
              id="feedback-user"
              placeholder="Password"
              autocomplete="off"
              >
            </b-form-input>

          </b-row>
          <b-row class="mb-3" v-if="errorMessageText != ''">
            <label class="text-danger">{{ errorMessageText }}</label>
          </b-row>
          <b-row class="mb-3">
            <b-button v-if="!locked" block variant="primary" @click="submitLockNote">Lock</b-button>
            <b-button v-if="locked" block variant="primary" @click="submitUnlockNote">Unlock</b-button>
          </b-row>
        </b-container>
      </div>
      <!--End locked Note-->
   </b-form>
  </div>
</template>

<script>
export default {
  name:'home',
  title () {
    return `EINMB | Note it, fast and easy!`
  },
  data() {
    return {
      note: '',
      name: 'note',
      furl: '',
      processSaveNote: true,
      notifyMe: false,
      showLoginForm: false,
      loginTitleText: '',
      locked: false,
      notePassword: '',
      errorMessageText: '',
      views: 0,
      subscriptions: 0,
    }
  },
  created(){
    this.setIsLoading(true);
    this.$store.dispatch('retrievePublicNote',{
          name: this.$route.params.name ?? "",
        })
    .then(response=>{
        
      this.processSaveNote = false;
      this.name = this.$route.params.name ?? response.data.name;
      this.note =  response.data.note ?? '';
      if (this.$route.params.name == null)
        window.history.pushState('url', this.name, '/'+ this.name);
      this.furl = window.location.href;
      this.notifyMe = response.data.notifyMe;
      this.locked = response.data.locked;
      this.views = response.data.views;
      this.subscriptions = response.data.subscriptions;

    })
    .catch(error => {
        this.locked = true;
        this.showLoginToUnlockNote(this.$route.params.name);
    })
    .finally( () => {
      this.processSaveNote = true;
      this.setIsLoading(false);
    });
  },
  computed: {
    charCounter(){
      return this.note.length + " of 5000 chars";
    },
  },
  watch: {
    // whenever question changes, this function will run
    note() {
        if (this.processSaveNote)
          this.saveNote();
    }
  },
  mounted() {
    this.focusPanel()
  },
  methods: {
    saveToMyNotes(){
      
      if(this.name == "" || this.note == ""){
        this.makeToast("Please fill note field correctly!", "danger");
        return;
      }

      if(this.$store.getters.loggedIn)
        this.$router.push({ name: 'notes', params: {
          publicNoteTitle: this.name, 
          publicNoteBody: this.note} });
      else
        this.$router.push({ name: 'login', query: { 
              redirect: { 
                name: 'notes', 
                params: {
                  publicNoteTitle: this.name, 
                  publicNoteBody: this.note
                }
              } 
          } 
        });
    },
    setIsLoading(isLoading){
      this.$store.dispatch('isLoading', isLoading);
    },
    focusPanel() {
      this.$refs.notePanel.focus();
    },
    saveNote(){
      // 
      this.$store.dispatch('savePublicNote', {
        name: this.name,
        body: this.note,
      })
      .catch(error => {
        this.makeToast(error.response.data.message, "danger");
        this.locked = error.response.data.locked;
        this.errorMessageText = error.response.data.message;
        this.showLoginToUnlockNote(error.response.data.name);
      });  
    },
    copyToClipboard(){
      navigator.clipboard.writeText(this.furl);
      this.makeToast("Copied link!", "warning");
    },
    makeToast(message,variant, append = false) {
      this.toastCount++
      this.$bvToast.toast(`${message}`, {
        title: 'Info',
        autoHideDelay: 5000,
        appendToast: append,
        variant: `${variant}`
      })
    },
    subscribeForNotifications(){
      
      Notification.requestPermission().then((permission)=> {
          if (permission === 'granted') {
              // get service worker
              navigator.serviceWorker.ready.then((sw)=> {
                  // subscribe
                  sw.pushManager.subscribe({
                      userVisibleOnly: true,
                      applicationServerKey: "BHW3VOGt0w3ZbTCT-TvzS_oQPW-vGF-iTq7PvceVNhfsTMPU92uBV89UPjBb3Qx8suEtFze257mQTREpM7kk758"
                  }).then((subscription)=> {
                      this.subscribe(subscription);
                  });
              });
          }
      });

    },
    subscribe(subscription){
      
      this.setIsLoading(true);
      this.$store.dispatch('enableNotifications',{
            name: this.name,
            subscription: subscription,
          })
      .then(response=>{ 
        
        this.notifyMe = true;
        this.subscriptions = response.data.subscriptions;
        this.makeToast(response.data.message, "warning");

      }).catch(error => {

        this.makeToast(error.response.data.message, "danger");
      
      }).finally( () => {
      
        this.setIsLoading(false);
      
      });

    },
    unsubscribeNotifications(){

      this.setIsLoading(true);
      this.$store.dispatch('disableNotifications',{
            name: this.name,
          })
      .then(response=>{ 
        
        this.notifyMe = false;
        this.subscriptions = response.data.subscriptions;
        this.makeToast(response.data.message, "warning");
        
      }).catch(error => {
        this.makeToast(error.response.data.message, "danger");
      }).finally( () => {
        this.setIsLoading(false);
      });
      
    },
    showLoginToLockNote()
    {
    
      this.loginTitleText = "Set a password for `" + this.name + "`!";
      this.showLoginForm = true;
    
    },
    showLoginToUnlockNote(note_name)
    {
      
      this.loginTitleText = "Note `" + note_name + "` is locked!";
      this.showLoginForm = true;

    },
    submitLockNote()
    {
    
      this.setIsLoading(true);
      this.$store.dispatch('lockPublicNote',{
            note_name: this.name,
            password: this.notePassword
          })
      .then(response=>{ 

        this.processSaveNote = false;
        this.showLoginForm = false;
        this.locked = true;
        this.makeToast("Note is locked!", "warning");

      }).catch(error => {
        this.makeToast(error.response.data.message, "danger");
      }).finally( () => {
        this.processSaveNote = true;
        this.resetFields();
        this.setIsLoading(false);
      });

    },
    submitUnlockNote()
    {
      this.setIsLoading(true);
      this.$store.dispatch('unlockPublicNote',{
            name: this.$route.params.name,
            password: this.notePassword
          })
      .then(response=>{ 
        
        this.processSaveNote = false;
        this.name = this.$route.params.name ?? response.data.name;
        this.note = response.data.note ?? '';
        this.notifyMe = response.data.notifyMe;
        this.locked = response.data.locked;
        this.showLoginForm = false;
        this.views = response.data.views;
        this.subscriptions = response.data.subscriptions;

        this.makeToast(response.data.message, "warning");

      }).catch(error => {
        this.makeToast(error.response.data.message, "danger");
      }).finally( () => {
        this.processSaveNote = true;
        this.resetFields();
        this.setIsLoading(false);
      });

    },
    submitRemoveLock()
    {

      this.setIsLoading(true);
      this.$store.dispatch('removeLock',{
            name: this.$route.params.name,
          })
      .then(response=>{ 
        
        this.processSaveNote = false;
        this.locked = false;
        this.showLoginForm = false;
        this.makeToast(response.data.message, "warning");

      }).catch(error => {
        this.makeToast(error.response.data.message, "danger");
      }).finally( () => {
        this.processSaveNote = true;
        resetFields();
        this.setIsLoading(false);
      });

    },
    submitLogOut(){

      this.setIsLoading(true);
      this.$store.dispatch('publicLogout',{
            name: this.$route.params.name,
          })
      .then(response=>{ 
        
        this.processSaveNote = false;
        this.locked = true;
        this.showLoginForm = true;
        this.showLoginToUnlockNote(this.$route.params.name);
        this.makeToast(response.data.message, "warning");

      }).catch(error => {
        this.makeToast(error.response.data.message, "danger");
      }).finally( () => {
        this.processSaveNote = true;
        this.resetFields();
        this.setIsLoading(false);
      });

    },
    resetFields()
    {

      this.notePassword = '';
    
    }
  }
}
</script>