<template>

    <b-container>

    <div class="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
      <h1 class="display-4">Reset Password</h1>
      <p class="lead" v-if="verified">{{verifyMsg}}</p>
    </div>

    <div class="card-deck mb-3 text-center">
        <div class="card box-shadow">
          <div class="card-body">

            <template v-if="!verified && verifyMsg != ''">
            <b-card bg-variant="dark" text-variant="white" title="Invalid data!">
              <b-card-text>
                {{verifyMsg}}
              </b-card-text>
              <b-button to="/login" variant="primary">Back</b-button>
            </b-card>
            </template>

            <template v-else-if="verified">

            <form action="#" @submit.prevent="resetConfirm">

              <div class="form-group text-left">
                <label>Password</label>
                <input type="password" class="form-control" v-model="password" placeholder="Password" required>
              </div>

              <div class="form-group text-left">
                <label>Password Confirmation</label>
                <input type="password" class="form-control" v-model="password_confirmation" placeholder="Password Confirmation" required>
              </div>

              <b-container fluid>
                 <b-row>
                    <b-col>
                      <b-button pill block variant="primary" size="md" type="submit">Change Password</b-button>
                    </b-col>
                    <b-col align-self="center">
                      <b-link class="text-right" to="/login">Back</b-link>
                    </b-col>
                  </b-row>
              </b-container>

            </form>

            </template>
          </div>
        </div>
      </div>
    </b-container>
</template>

<script>
export default {
  name: 'reset-confirm',
  title: 'EINMB | Reset Confirmation',
  props: {
    token: {
      type: String
    },
    email: {
      type: String
    }
  },
  data() {
    return {
        username: '',
        password: '',
        password_confirmation: '',
        verifyMsg: '',
        verified: false,
    }
  },
  created(){
    this.setIsLoading(true);
    this.$store.dispatch('resetVerify',{
          token: this.token,
          email: this.email
        })
    .then(response => {
      this.verified = true;
      this.verifyMsg = response.data.message;
    })
    .catch(error =>{
      this.verifyMsg = error.response.data.message;
      this.verified = false;
    })
    .finally( () => {
      this.setIsLoading(false);
    });
  },
  watch:{
  },
  mounted(){
  },
  methods: {
    resetConfirm(){
      if (this.password_confirmation != this.password){
        this.makeToast("`Password` and `Password Confirmation` should be the same!", "danger");
        return;
      }

      this.setIsLoading(true);
      this.$store.dispatch('resetConfirm',{
            token: this.token,
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation
          })
      .then(response => {
        this.$router.push({ name: 'login', params: {dataSuccessMessage: response.data.message}  });
      })
      .catch(error =>{
        this.makeToast(error.response.data.message, "danger");
      })
      .finally( () => {
        this.setIsLoading(false);
      });
    },
    setIsLoading(isLoading){
      this.$store.dispatch('isLoading', isLoading);
    },
    makeToast(message,variant,append = false) {
      this.toastCount++
      this.$bvToast.toast(`${message}`, {
        title: 'Info',
        autoHideDelay: 5000,
        appendToast: append,
        variant:`${variant}`
      })
    },
    
  }
}
</script>
