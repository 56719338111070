<template>
  <div>
    <div ref="detail" v-bind:class="{'default': !expanded}" 
      v-bind:style="`--lines: ${lines}`">
          {{text}}
    </div>

    <a
      v-if="hasMore && triggerShowMore"
      class="anchor"
      @click="onClick"
      >Show more</a
    >
    <a
      v-if="hasMore && expanded"
      class="anchor"
      @click="onClick"
      >Show less</a
    >
  </div>
</template>

<script>
export default {
  name: 'show-more-text',
  props: {
    lines: {
      type: Number,
      default: 3,
    },
    text: {
      type: String,
      default: '',
    },
    hasMore: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      expanded: false,
      triggerShowMore: false,
    }
  },
  computed: {
  },
  mounted() {
    this.$nextTick(function () {
      this.determineShowMore()
    })
  },
  updated() {
    this.$nextTick(function () {
      this.determineShowMore()
    })
  },
  methods: {
    onClick() {
      this.expanded = !this.expanded
      this.$emit('click', this.expanded);
    },
    determineShowMore() {
      if (
        this.$refs.detail &&
        this.$refs.detail.offsetHeight < this.$refs.detail.scrollHeight
      ) {
        this.triggerShowMore = true
      } else {
        this.triggerShowMore = false
      }
    },
  },
}
</script>
<style scoped>
.default{
  --lines: 3;
  white-space: pre-line; 
  display: -webkit-box; 
  max-width:100%; 
  -webkit-line-clamp: var(--lines); 
  -webkit-box-orient: vertical; 
  overflow: hidden; 
  text-overflow: ellipsis;
}

.anchor{
  display: block; 
  text-align: right; 
  padding: 8px; 
  margin-bottom: 4px;
  color: #1976d2;
  cursor: pointer;
}
</style>