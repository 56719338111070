<template>
  <div>
    <spinner :spinnerIsActive="isLoading"/>
    <div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
      <h5 class="my-0 mr-md-auto font-weight-normal"><a href="/" class="text-decoration-none text-reset">EINMB</a></h5>
      <nav class="my-2 my-md-0 mr-md-3">        
       <router-link
          :to="{ name: 'notes' }"
          custom
          v-slot="{ href, navigate }"
        >
          <a v-if="loggedIn" class="p-2 text-dark" :href="href" @click="navigate">Notes</a>
        </router-link>

        <router-link
          :to="{ name: 'login' }"
          custom
          v-slot="{ href, navigate }"
        >
          <a v-if="!loggedIn" class="p-2 text-dark" :href="href" @click="navigate">Sign in</a>
        </router-link>

      </nav>
      
      <router-link
        :to="{ name: 'register' }"
        custom
        v-slot="{ href, navigate }"
      >
        <button v-if="!loggedIn" class="btn btn-outline-primary" :href="href" @click="navigate">Sign up</button>
      </router-link>

      
      <div v-if="loggedIn">
      <b-dropdown right variant="outline-primary">
        <template #button-content>
          {{username}} <b-icon icon='person'></b-icon>
        </template>
        <b-dropdown-item :to="{ name:'logout'}">Logout</b-dropdown-item>
      </b-dropdown>
    </div>
    </div>

    <!--end new-->
    <b-container fluid>  
      <transition name="router-animation" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in">
      <router-view></router-view>
      </transition>

      <b-alert 
        show 
        dismissible
        @dismissed="dismiss()"
        v-if="!cookie_comply"
      >
        This website uses cookies to ensure you get the best experience on our website. <a href="https://www.internetcookies.com/">Learn More</a>
      </b-alert>

      <footer class="pt-4 my-md-1 pt-md-1 border-top text-center">
          <div class="row">
              <div class="col-12 col-md">
                  <kbd>EINMB</kbd>
                  <small class="d-block mb-3 text-muted">info@xqsem.net © 2022</small>
              </div>
          </div>
      </footer>
    </b-container>

  </div>
</template>

<script>
import Spinner from './components/loading/Spinner.vue'
export default {
  components: {
    Spinner,
  },
  data() {
    return {
      username: '',
      cookie_comply: localStorage.getItem('cookie_comply') ?? false,
    }
  },
  watch: {
     loggedIn() {
       this.setUserName();
     }
  },
  created(){
    this.setUserName();
  },
  methods:{
    setUserName(){
      if (this.loggedIn === true){
        this.$store.dispatch('retrieveName')
          .then(response=>{
            this.username = response.data.username
        }).catch(() => {
          this.$router.push({ name: 'logout' });
        });
      }
    },
    dismiss(){
      localStorage.setItem('cookie_comply', true);
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn
    },
    isLoading(){
      return this.$store.getters.isLoading
    }
  }
}
</script>
