import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import App from './App.vue'
import { store } from './store'
import CxltToastr from 'cxlt-vue2-toastr'
import titleMixin from './helper/titleMixin'

//bootstrap +
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Register serviceWorker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch(error => {
          console.error('Service Worker registration failed:', error);
        });
    });
}

const toastrConfigs = {
    position: 'bottom right',
    showDuration: 2000,
    timeOut: 5000,
    progressBar: true,
}

window.eventBus = new Vue();

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(CxltToastr, toastrConfigs)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
//bootstrap -

//title +
Vue.mixin(titleMixin)
//title -

const router = new VueRouter({
    routes,
    mode: 'history'
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.loggedIn) {
            next({
                name: 'login',
            })
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.requiresVisitor)) {
        if (store.getters.loggedIn) {
            next({
                name: 'notes',
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

new Vue({
    render: h => h(App),
    store,
    router,
    components: { App }
  }).$mount('#app')