<template>

    <b-container>

    <div class="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
      <h1 class="display-4">Reset Password</h1>
    </div>

    <div class="card-deck mb-3 text-center">
        <div class="card box-shadow">
          <div class="card-body">
            <form action="#" @submit.prevent="reset">

              <div class="form-group text-left">
                <label for="exampleInputEmail1">Email address</label>
                <input type="email" class="form-control" v-model="email" placeholder="Email" required>
              </div>

              <div class="form-group text-left" v-if="production === 'true'">
                <vue-hcaptcha 
                  sitekey="dcbddb33-51d4-4dfe-bb45-0fab3969987e"
                  @verify="onVerify"
                  @expired="onExpire"
                  @challengeExpired="onExpire"
                  @error="onError"
                ></vue-hcaptcha>

                <small v-if="error" id="error" class="form-text text-muted text-danger">{{ error }}</small>
                <small v-if="expired" id="expired" class="form-text text-muted text-danger">Challenge expired!</small>
              </div>

              <b-container fluid>
                 <b-row>
                    <b-col>
                      <b-button pill block variant="primary" size="md" type="submit">Send Reset Link</b-button>
                    </b-col>
                    <b-col align-self="center">
                      <b-link class="text-right" to="/login">Back</b-link>
                    </b-col>
                  </b-row>
              </b-container>

            </form>
          </div>
        </div>
      </div>
    </b-container>
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
export default {
  components: { VueHcaptcha },
  name: 'reset-password',
  title: 'EINMB | Reset Password',
  props: {
    
  },
  data() {
    return {
        email:'',
        //hcaptcha + 
        verified: false,
        expired: false,
        token: null,
        eKey: null,
        error: null,
        //hcaptcha -
        site_key: process.env.VUE_APP_HCAPTCHA_SITEKEY,
        production: process.env.VUE_APP_PRODUCTION
    }
  },
  watch:{
  },
  mounted(){
  },
  methods: {
    reset(){
      
      if (this.verified == false && this.production == 'true'){
        this.makeToast("Please check Captcha errors!", "danger");
        return;
      }

      this.setIsLoading(true);
      this.$store.dispatch('resetPassword', {
        email: this.email,
        hcaptcharesponse: this.token
      }).then(response => {
          this.$router.push({ name: 'login', params: {dataSuccessMessage: response.data.message} });
        })
        .catch(error => {
          this.makeToast(error.response.data.message, "danger");
        }).finally(()=>{
          this.setIsLoading(false);
        });
    },
    setIsLoading(isLoading){
      this.$store.dispatch('isLoading', isLoading);
    },
    makeToast(message,variant,append = false) {
      this.toastCount++
      this.$bvToast.toast(`${message}`, {
        title: 'Info',
        autoHideDelay: 5000,
        appendToast: append,
        variant:`${variant}`
      })
    },

    //hcaptcha + 
    
    onVerify(token, ekey) {
        this.verified = true;
        this.expired = false;
        this.token = token;
        this.eKey = ekey;
    },
  
    onExpire() {
        this.verified = false;
        this.token = null;
        this.eKey = null;
        this.expired = true;
    },

    onError(err) {
        this.token = null;
        this.eKey = null;
        this.error = err;
    },
    
    //hcaptcha -
    
  }
}
</script>
